<div class="col-xl-4 col-lg-6 col-md-5 col-sm-12">
    <form [formGroup]="newsForm" novalidate (submit)="onSubmit()" #myform="ngForm" class="hero-form">

        <label for="validationCustomUsername">Date</label>
        <div class="input-group">
            <input type="date" class="form-control" placeholder="Description" id="validationCustomUsername" aria-describedby="inputGroupPrepend2" formControlName="date">
        </div>
        <div *ngIf= "newsForm.get('date').hasError('required') && myform.submitted" style="color: red;">*Select Date</div>

        <label for="validationCustomUsername">Title</label>
        <div class="input-group">
            <input type="text" class="form-control" placeholder="Title" id="validationCustomUsername" aria-describedby="inputGroupPrepend2" formControlName="heading">
        </div>
        <div *ngIf= "newsForm.get('heading').hasError('required') && myform.submitted" style="color: red;">*Enter Title</div>

        <label for="validationCustomUsername">Description</label>
        <div class="input-group">
            <textarea class="form-control" id="validationCustomUsername" rows="3" placeholder="Description" aria-describedby="inputGroupPrepend2" formControlName="description"></textarea>
        </div>
        <div *ngIf= "newsForm.get('description').hasError('required') && myform.submitted" style="color: red;">*Enter Description</div>

        <button type="submit" class="bttn-mid btn-fill w-100 mt-20"><i class="ti-check"></i>Continue</button>
    </form>
</div>