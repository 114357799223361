
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header-component',
  templateUrl: './header-component.component.html',
  styleUrls: ['./header-component.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(private router: Router) { }
  isAdmin: boolean;
  isLoggedIn: boolean;
  localStorageDataAll: any;
  phoneNo: any;
  ngOnInit(): void {
    let adminData = localStorage.getItem('adminUserData');
    if (adminData == null || adminData == undefined) {
      let userData = localStorage.getItem('loggedInUserData');
      this.isAdmin = false;
      if (userData == null || userData == undefined) {
        this.isLoggedIn = false;
      }
      else {
        this.isLoggedIn = true;
        let userData = localStorage.getItem('loggedInUserData');
        let data = JSON.parse(userData);
        this.phoneNo = data[0]['phone'];
      }
    }
    else {
      this.isAdmin = true;
    }

  }

  redirectHowItWorks() {
    this.router.navigate(['/how-it-works']);
  }

  redirectPaynow() {
    this.router.navigate(['/pay-now']);
  }

  redirectPaybills() {
    this.router.navigate(['/pay-bills'])
  }

  redirectLogin() {
    this.router.navigate(['/log-in']);
  }

  redirectRegister() {
    this.router.navigate(['/registration']);
  }

  redirectPrivacy() {
    this.router.navigate(['/privacy-policy']);
  }

  redirectDashboard() {
    this.router.navigate(['/dashboard']);
  }

  redirectContactus() {
    this.router.navigate(['/contactus']);
  }
  redirectuserList() {
    this.router.navigate(['/user-list']);
  }
  redirectTransactions() {
    this.router.navigate(['/transactions']);
  }
  redirectNews() {
    this.router.navigate(['/news-list']);
  }
  redirectProvider() {
    this.router.navigate(['/merchant-list']);
  }
  logOut() {

    localStorage.clear();
    this.router.navigate(['/log-in']);
  }
  redirectUserTransaction() {
    this.router.navigate(['/user-transaction'])
  }
  redirectProfile() {
    this.router.navigate(['/user-profile'])
  }
  redirectAdminProfile() {
    this.router.navigate(['/admin-profile'])
  }
  
}
