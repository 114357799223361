import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators, NgForm, FormGroupDirective, FormBuilder } from '@angular/forms';
import { UserService } from '../user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { interval, Subscription } from 'rxjs';
//import moment from 'moment';
import 'moment-timezone'
import * as moment from 'moment-timezone';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy {

  constructor(private formBuilder: FormBuilder, private user: UserService,
    private router: Router, private spinner: NgxSpinnerService, private toastr: ToastrService) { }

  dashboardForm: FormGroup;
  paymentDetails: any;
  isLoggedIn: boolean;
  newsData: any;
  subscription: Subscription;
  intervalId: number;
  transactDate: any;
  disburseInProgress: boolean;
  isCommissionCalculated: boolean;
  paymentMasterData: any;
  totalAmount: any;
  ngOnInit(): void {
    this.isCommissionCalculated = false;
    this.disburseInProgress = false;

    let userData = localStorage.getItem('loggedInUserData');
    if (userData == undefined || userData == null) {
      this.isLoggedIn = false;
    }
    else {
      this.isLoggedIn = true;
      this.spinner.show();
      const statusData = new FormData();
      const data = JSON.parse(userData);
      statusData.append('token', data[0]['token']);
      this.user.payment_details(statusData).subscribe(paymentData => {
        if (paymentData['msg'] != "Token mismatch") {
          this.paymentMasterData = paymentData['details'];
          const json = {
            "email": this.paymentMasterData[0]["payworld_email"],
            "password": this.paymentMasterData[0]["payworld_password"]
          }
          this.user.paymentGatewayLogin(json).subscribe(data => {

            if (data['message'] == "Successfully logged in") {
              localStorage.setItem('masterInfo', JSON.stringify(data['data']))
              // this.user.getnewsList().subscribe(data => {
              //   this.newsData = data['newslist'];
              //   console.log(this.newsData)
              // })
              // error => {
              //   this.newsData = error.message;
              //   console.error('There was an error!', error);
              // }
            }
            else {
              //  alert(data["msg"]);
              this.toastr.error(data["msg"]);
            }
            this.spinner.hide();

          });
        }
        else {
          localStorage.clear();
          this.spinner.hide();

        }
      });
    }
    this.dashboardForm = this.formBuilder.group({
      selectMerchant: ['', Validators.required],
      amount: ['', Validators.required],
      phone: ['', [Validators.required, Validators.pattern('^[2-9]{2}[0-9]{8}$')]],
    });


  }
  redirectLogin() {
    this.router.navigate(['/log-in']);
  }
  calculateCommission() {

    if (this.dashboardForm.value['amount'] != "") {
      // this.dashboardForm.reset();
      let transactionamount = parseInt(this.dashboardForm.value['amount']);
      if (transactionamount >= 500) {

        if (this.dashboardForm.value['selectMerchant'] != null && this.dashboardForm.value['selectMerchant'] != "") {

          var amount = this.dashboardForm.value['amount'];
          let commission = 0;
          let perc = "";
          let userData = localStorage.getItem('loggedInUserData');
          userData = JSON.parse(userData);
          if (userData[0]['channelName'] == this.dashboardForm.value['selectMerchant']) {
            commission = this.paymentMasterData[0]['same_c'];
            this.isCommissionCalculated = true;
            perc = ((commission * amount) / 100).toFixed(3);
            this.totalAmount = Math.ceil(parseFloat(this.dashboardForm.value['amount']) + parseFloat(perc));
          }
          else {
            commission = this.paymentMasterData[0]['cross_c'];
            this.isCommissionCalculated = true;
            perc = ((commission * amount) / 100).toFixed(3);
            this.totalAmount = Math.ceil(parseFloat(this.dashboardForm.value['amount']) + parseFloat(perc));
          }
          // if (isNaN(commission) || isNaN(amount)) {
          //   perc = " ";
          // } else {
          //   perc = ((commission / amount) * 100).toFixed(3);
          // }
        } else {
          this.toastr.error('Please Select Merchant first');
          this.dashboardForm.value['amount'] = 0;
        }
      }
      else {
        this.toastr.error('Please enter amount greater than 500');
      }

    }

  }
  redirectRegister() {
    this.router.navigate(['/registration']);
  }

  onSubmit() {
    let amount = parseInt(this.dashboardForm.value['amount']);
    if (amount >= 500) {
      if (this.dashboardForm.value['phone'] != "" && this.dashboardForm.value['amount'] != "" && this.dashboardForm.value['selectMerchant'] != "" && this.totalAmount != "") {
        this.spinner.show();
        let userData = localStorage.getItem('loggedInUserData');
        userData = JSON.parse(userData);
        const statusData = new FormData();
        statusData.append('token', userData[0]['token']);
        this.user.payment_details(statusData).subscribe(data => {
          let responseData = data["details"];
          this.paymentDetails = {};
          if (userData == undefined || userData == null) {
            this.router.navigate(['/log-in']);
          }
          this.paymentDetails['channel'] = userData[0]['channelName'];

          this.paymentDetails['merchantId'] = data["details"][0]["airtelMerchantId"];
          this.paymentDetails['amount'] = this.totalAmount;
          this.paymentDetails['channel_params'] = {}
          // this.paymentDetails['channel_params']['msisdn'] = this.dashboardForm.value['phone'];
          this.paymentDetails['channel_params']['msisdn'] = userData[0]['phone'];

          if (userData[0]['channelName'] == 'mtn') {
            this.paymentDetails['channel_params']['currency'] = "XAF";
            this.paymentDetails['channel_params']['payerMessage'] = "Test";
            this.paymentDetails['channel_params']['payeeNote'] = "TestNote";
            this.paymentDetails['merchantId'] = data["details"][0]['mtnMerchantId'];
          }
          this.paymentDetails['tillNumber'] = data["details"][0]['tillNumber'];
          this.paymentDetails['confirmation_url'] = data["details"][0]['confirmationUrl'];
          var orderReferencTime = moment.utc(new Date().toUTCString()).tz("Africa/Nairobi");
          var a = moment.utc(new Date().toUTCString()).tz("Africa/Nairobi");
          let time = moment(a).format("hh:mm:ss")
          let loggedInUserData = JSON.parse(localStorage.getItem('loggedInUserData'));
          this.paymentDetails['orderReference'] = "CA-001-" + loggedInUserData[0]['userId'] + "-" + time.toString();
          var unix = Math.round(Date.now() / 1000);
          var a = moment.utc(new Date().toUTCString()).tz("Africa/Nairobi");
          this.transactDate = a.format('YYYY-MM-DD HH:mm:ss');
          //var transctDate= moment(unix).tz('America/Phoenix').format('YYYY-MM-DD HH:mm:ss')

          this.paymentDetails['transactionDate'] = this.transactDate;
          //   let info={};
          const info = new FormData();

          info.append('channel', this.dashboardForm.value['selectMerchant']);
          info.append('amount', this.totalAmount);
          info.append('merchantId', this.paymentDetails['merchantId']);
          info.append('orderReference', this.paymentDetails['orderReference']);
          info.append('transactionDate', this.transactDate);
          //   info['tillNumber'] ="444234";

          info.append('msisdn', loggedInUserData[0]['phone']);
          info.append('senderNo', loggedInUserData[0]['phone']);
          //   info['currency']='XAF';
          info.append('receiverNo', '242' + this.dashboardForm.value['phone']);
          //    info['payeeNote'] = "TestNote";
          //    info['payerMessage']="Test";
          //   info['userId']=loggedInUserData[0]['userId'];
          info.append('json_resp', JSON.stringify(this.paymentDetails));


          this.user.storeTransaction(info).subscribe(transactionInfo => {

            this.user.initiatePayment(this.paymentDetails).subscribe(paymentInfo => {
              let data = paymentInfo;
              this.toastr.success('Request Received');

            }, (err) => {
              if (err.status == 200) {
                const source = interval(1000);
                const text = 'Your Text Here';
                this.toastr.success('Please do not refresh the page.Your transaction is in progress');
                this.subscription = source.subscribe(val => {
                  if (val <= 100) {
                    const statusData = new FormData();
                    statusData.append('orderReference', this.paymentDetails['orderReference']);
                    statusData.append('token', userData[0]['token']);
                    this.user.status(statusData).subscribe(status => {
                      if (status["statusUpdate"][0]["status"] == "200" || status["statusUpdate"][0]["status"] == "SUCCESS" ||
                        status["statusUpdate"][0]["status"] == "SUCCESSFUL") {
                        this.subscription && this.subscription.unsubscribe();

                        // clearInterval(this.intervalId);
                        this.disbursedPayment(data["details"][0]['tillNumber']);
                      } else if (status["statusUpdate"][0]["status"] == "FAILED") {
                        this.subscription && this.subscription.unsubscribe();
                        this.dashboardForm.reset({
                          selectMerchant: '',
                          amount: '',
                          phone: ''
                        });
                        this.toastr.error('Trasnsaction Failed. PLease try again.');
                        this.spinner.hide();
                      }

                    });
                  }
                  else {
                    this.subscription && this.subscription.unsubscribe();
                    this.toastr.error('Trasnsaction Failed. PLease try again.');
                    this.spinner.hide();
                    this.dashboardForm.reset({
                      selectMerchant: '',
                      amount: '',
                      phone: ''
                    });
                  }
                });
              }
              else{
                this.toastr.error('Trasnsaction Failed. PLease try again.');
              }
              //  console.log(err);

            })
            //}, 3000);



          })

          // console.log(this.dashboardForm.value);
        })
      }
      else {
        this.toastr.error('Please fill all the fields');
      }
    }
    else {
      this.toastr.error('Please enter amount greater than 500');
    }

  }
  disbursedPayment(tillNumber) {
    if (!this.disburseInProgress) {
      this.disburseInProgress == true;
      let disbursedObject = {}
      disbursedObject['tillNumber'] = tillNumber;
      disbursedObject['amount'] = this.dashboardForm.value['amount'];
      disbursedObject['channel'] = this.dashboardForm.value['selectMerchant'];
      disbursedObject['merchantId'] = this.paymentDetails['merchantId'];
      disbursedObject['mobilenumber'] = '242' + this.dashboardForm.value['phone'];
      disbursedObject['confirmation_url'] = this.paymentDetails['confirmation_url'];
      disbursedObject['orderReference'] = this.paymentDetails['orderReference'];
      disbursedObject['transactionDate'] = this.transactDate;
      if (disbursedObject['channel'] == "mtn") {
        disbursedObject['currency'] = "XAF";
        disbursedObject['payerMessage'] = "Test";
        disbursedObject['payeeNote'] = "TestNote";

      }
      //setTimeout(()=>{                           //<<<---using ()=> syntax
      this.user.completePayment(disbursedObject).subscribe(details => {
        this.spinner.hide();
        debugger;
        this.dashboardForm.reset({
          selectMerchant: '',
          amount: '',
          phone: ''
        });
        this.toastr.success('Request Received');
      }, (err) => {
        debugger;
        this.spinner.hide();
        this.dashboardForm.reset({
          selectMerchant: '',
          amount: '',
          phone: ''
        });
        this.toastr.success('Transaction Completed Successfully');
      })
    }
  }
  ngOnDestroy() {
    if (this.subscription)
      this.subscription.unsubscribe()
  }
}

