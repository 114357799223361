import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  headers =
    {
      'content-type': 'application/x-www-form-urlencoded',
    };
  //  url = "http://demo.techwise-apps.com/cashless-api/api/";
  url = "https://www.cashless.cg/cashless-cg-api/api/";

  constructor(private http: HttpClient) { }
  adminLogInPost(AdminlogInFormData) {
    const formData = new FormData();
    formData.append('username', AdminlogInFormData['username']);
    formData.append('password', AdminlogInFormData['password']);

    return this.http.post(this.url + 'adminlogin', formData);
  }

  UserLogInPost(logInFormData) {
    const formData = new FormData();
    formData.append('phone', '242' + logInFormData['phone']);
    formData.append('password', logInFormData['password']);

    return this.http.post(this.url + 'userlogin', formData);
  }

  UserProfileData(userId) {
    const formData = new FormData();
    formData.append('userId', userId);
    return this.http.post(this.url + 'userdetails', formData);
  }
  userRegistration(registrationData) {
    const formData = new FormData();
    formData.append('firstname', registrationData['firstname']);
    formData.append('lastname', registrationData['lastname']);
    formData.append('email', registrationData['email']);
    formData.append('phone', '242' + registrationData['phone']);
    formData.append('password', registrationData['password']);
    formData.append('confirmPassword', registrationData['confirmPassword']);
    formData.append('channelId', registrationData['channelId']);
    console.log(formData);
    return this.http.post(this.url + 'userregister', formData);
  }
  getUserProfileList(data) {
    return this.http.post(this.url + 'userlist', data);
  }
  newsForm(newsFormData) {
    let body = new URLSearchParams();
    body.set('date', newsFormData['date']);
    body.set('heading', newsFormData['heading']);
    body.set('description', newsFormData['description']);

    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };

    return this.http.post(this.url + 'news', body.toString(), options);
  }

  merchantForm(merchantAddFormData) {
    let body = new URLSearchParams();
    body.set('providerName', merchantAddFormData['providerName']);

    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };

    return this.http.post(this.url + 'addmerchant', body.toString(), options);
  }
  getnewsList() {
    return this.http.get(this.url + 'newsList');
  }
  getChannelList() {
    return this.http.get(this.url + 'channellist');
  }
  paymentGatewayLogin(json) {

    return this.http.post("https://access.payworld.cg/api/v1/auth/CashlessLoginRequest", json)
  }
  payment_details(formData) {
    return this.http.post(this.url + 'payment_details', formData);

  }
  adminPRofiledetails(formData) {
    return this.http.post(this.url + 'admindetails_get', formData);

  }
  initiatePayment(data) {
    let masterInfo = JSON.parse(localStorage.getItem('masterInfo'));
    let token = masterInfo['access_token'];

    let options = {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + token)
    };

    return this.http.post('https://access.payworld.cg/api/v1/payment/cashlessRequest', data, options);

  }
  storeTransaction(data) {
    // let options = {
    //   headers: new HttpHeaders().set('Content-type', 'applicaton/json')
    // };
    return this.http.post(this.url + 'transaction', data);
  }
  completePayment(data) {
    let masterInfo = JSON.parse(localStorage.getItem('masterInfo'));
    let token = masterInfo['access_token'];

    let options = {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + token)
    };
    return this.http.post('https://access.payworld.cg/api/v1/disbursement/cashlessRequest', data, options);

  }
  getTransaction(formData) {
    return this.http.post(this.url + 'transactionlist', formData);
  }
  transactionData(transList) {
    // let options = {
    //   headers: new HttpHeaders().set('Content-Type', 'multipart/form-data')
    // };
    return this.http.post(this.url + 'usertransaction', transList);
  }
  status(data) {
    return this.http.post(this.url + 'status', data);
  }
  adminpass(data) {
    const formData = new FormData();
    formData.append('newpass', data['newpass']);
    formData.append('password', data['password']);
    formData.append('token', data['token']);
    return this.http.post(this.url + 'adminpass', formData)
  }
}
