import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../user.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
// import { ConfirmedValidator } from './confirmed.validator';
@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})

export class RegistrationComponent implements OnInit {


  registrationData: any;
  constructor(public router: Router, private formBuilder: FormBuilder, private user: UserService,
    private spinner: NgxSpinnerService, private toastr: ToastrService) { }
  userRegistrationForm: FormGroup;

  ngOnInit(): void {
    this.userRegistrationForm = this.formBuilder.group({
      firstname: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      email: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      password: ['',[Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', [Validators.required]],
      channelId:['',[Validators.required]]
      // }, {
      //   validator: ConfirmedValidator('password', 'confirm_password')
    })
  }
  onSubmit() {
    if (this.userRegistrationForm.valid) {
      this.registrationData = this.userRegistrationForm.value,
        console.log(this.registrationData);
      this.spinner.show();
      this.user.userRegistration(this.registrationData).subscribe(data => {
        let responseData = data
        if (data['msg'] == "Records added Successfully") {
          this.toastr.success('Account Created Successfully.')
          this.router.navigate(['/log-in']);
        }
        else {
          this.toastr.success(data["msg"]);
        }
        this.spinner.hide();
      })
    }
  }

  redirectLogin() {
    this.router.navigate(['/log-in']);
  }
}
