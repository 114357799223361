<app-header-component></app-header-component>
<section class="breadcrumb-area gradient-overlay" style="background: url('assets/images/banner/3.jpg');">
    <div class="container">
        <div class="row">
            <div class="col-xl-12">
                <div class="site-breadcrumb">
                    <h2>News List</h2>
                </div>
            </div>
        </div>
    </div>
</section><!--/breadcrumb area-->

<!--Privacy Section -->
<section class="section-padding-sm-2 blue-bg-2">
    <div class="container">
        <div class="row justify-content-center">
            
            <div class="col-xl-12 col-lg-12 col-md-12 mb-20">
                <div class="transaction-list table-responsive">
                    <table class="table table-hover">
                        <thead>
                          <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Heading</th>
                            <th scope="col">Description</th>
                            <th scope="col">Date</th>
                          </tr>
                        </thead>
                        <tbody *ngFor="let item of fullData.newslist">
                        <tr>
                            <td>{{item.id}}</td>
                            <td>{{item.heading}}</td>
                            <td>{{item.description}}</td>
                            <td>{{item.date}}</td>
                          </tr>
                          
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>
