import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../user.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {

  constructor(private router: Router, private user: UserService) { }
  userList: [];
  ngOnInit(): void {
    let data = localStorage.getItem('adminUserData');
    if (data == undefined || data == null) {
      this.router.navigate(['/admin-login']);
    }
    else {
      const formData = new FormData();
      let userData = JSON.parse(data);
      formData.append('token', userData[0]['token']);
      this.user.getUserProfileList(formData).subscribe(data => {
        this.userList = data['userlist'];
      });
    }
  }

}
