import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LogInComponent } from './log-in/log-in.component';
import { PayNowComponent } from './pay-now/pay-now.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { HowItWorksComponent } from './how-it-works/how-it-works.component';
import { RegistrationComponent } from './registration/registration.component';
import { PayBillsComponent } from './pay-bills/pay-bills.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ContactusComponent } from './contactus/contactus.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { AdminLoginComponent } from './admin-login/admin-login.component';
import { UserListComponent } from "./user-list/user-list.component";
import { NewsListComponent } from './news-list/news-list.component';
import { MerchantListComponent } from './merchant-list/merchant-list.component';
import  {NewsAddComponent} from './news-add/news-add.component';
import {MerchantAddComponent} from './merchant-add/merchant-add.component';
import {UserTransactionComponent} from './user-transaction/user-transaction.component'
import { AdminProfileComponent } from './admin-profile/admin-profile.component';



const routes: Routes = [
  { path: 'dashboard', component: DashboardComponent },
  // { path: '', component: DashboardComponent },
  { path: 'how-it-works', component: HowItWorksComponent },
  { path: 'log-in', component: LogInComponent },
   { path: '', component: DashboardComponent },
  { path: 'pay-now', component: PayNowComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'registration', component: RegistrationComponent },
  { path: 'pay-bills', component: PayBillsComponent },
  { path: 'contactus', component: ContactusComponent },
  { path: 'user-profile', component: UserProfileComponent },
  { path: 'admin-login', component: AdminLoginComponent },
  { path: 'transactions', component: TransactionsComponent },
  { path: 'user-list', component: UserListComponent },
  { path: 'news-list', component: NewsListComponent },
  { path: 'merchant-list', component: MerchantListComponent },
  { path: 'news-add', component: NewsAddComponent },
  { path: 'merchant-add', component: MerchantAddComponent },
  { path: 'user-transaction', component: UserTransactionComponent },
  { path: 'admin-profile', component: AdminProfileComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
