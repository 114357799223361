<app-header-component></app-header-component>
<ngx-spinner></ngx-spinner>
<section class="hero-area gradient-overlay"
    style="background: url('assets/images/banner/3.jpg') no-repeat center center;">
    <div class="container">
        <div class="row">
            <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12">
                <div class="hero-content">

                    <h2>Welcome to Cashless -<BR>Any 2 Any Payment</h2>
                    <ul>
                        <li>Cupiditate error, ipsa quos quis nam</li>
                        <li>Odit, voluptatum iure</li>
                        <li>Dolore ex eligendi asperiores vitae voluptatibus</li>
                        <li>Psum dolor sit amet consectetur</li>
                    </ul>
                    <div class="hero-btn">
                        <a href="javascript:void(0);" (click)="redirectLogin()" class="bttn-mid btn-fill"><i
                                class="ti-crown"></i> Account</a>
                    </div>

                </div>
            </div>

            <div class="col-xl-4 col-lg-6 col-md-5 col-sm-12">
                <form [formGroup]="dashboardForm" novalidate (submit)="onSubmit()" #myform="ngForm" class="hero-form">
                    <h4>Send Money</h4>
                    <label for="validationTooltipUsername">Reseux e envoie</label>
                    <div class="input-group" style="margin-bottom: 10px;">
                        <select name="currency" class="custom-select" formControlName="selectMerchant" (change)="calculateCommission()">
                            <option value="" disabled selected>Select Merchant</option>
                            <option value="mtn">MTN</option>
                            <option value="airtel">Airtel</option>
                        </select>
                        <div *ngIf="dashboardForm.get('selectMerchant').hasError('required') && myform.submitted"
                            style="color: red;">*Select Merchant</div>
                    </div>

                    <label for="validationTooltipUsername">You're sending</label>
                    <div class="input-group" style="margin-bottom: 10px;">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="inputGroupPrepend">XAF</span>
                        </div>
                        <input type="text" class="form-control" placeholder="500" id="validationTooltipUsername"
                            aria-describedby="inputGroupPrepend" formControlName="amount" (change)="calculateCommission()">
                        <div *ngIf="dashboardForm.get('amount').hasError('required') && myform.submitted"
                            style="color: red;">*Enter Amount</div>
                            <!-- <div *ngIf="dashboardForm.get('amount').hasError('min') && myform.submitted"  style="color: red;">Minimum amount is 500.</div> -->

                    </div>
                    <div *ngIf="isCommissionCalculated" style="margin-bottom: 10px;font-size: 20px;color:white">You will be charged {{totalAmount}} XAF</div>

                    <label for="validationCustomUsername">Mobile Number</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="inputGroupPrepend">242</span>
                        </div>
                        <input type="text" class="form-control" placeholder="9XXXXXXXXX" id="validationCustomUsername"
                            aria-describedby="inputGroupPrepend2" formControlName="phone">
                        <div *ngIf="dashboardForm.get('phone').hasError('required') && myform.submitted"
                            style="color: red;">*Enter Phone Number</div>
                    </div>

                    <!-- <p>Total fees - 8.55 USD </p> <p>The current exchange rate is 1 USD = 1.40 CAD</p> -->
                    <button type="submit" class="bttn-mid btn-fill w-100 mt-20"><i
                            class="ti-check"></i>Continue</button>
                </form>
            </div>
        </div>
    </div>
    <div class="hero-bg-alimation">
        <ul class="box">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
        </ul>
    </div>
</section>
<!--/Hero Area-->

<!--Map-->
<div class="section-padding pb-0 blue-bg">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-8 centered">
                <img src="assets/images/world-map-3.png" alt="">
            </div>
        </div>
    </div>
</div>
<!--/Map-->

<!--Why Choose Us-->
<section class="section-padding-3 blue-bg">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-8 centered">
                <div class="section-title mb-60">
                    <h4><span>New</span> Why Choose us</h4>
                    <h2>We're Special</h2>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                <div class="single-why-choose">
                    <div class="icon">
                        <i class="flaticon-007-user"></i>
                    </div>
                    <div class="title">
                        <h3>Stable Useability</h3>
                    </div>
                    <p>Voluptatibus, fugiat. Perspiciatis libero saepe ullam rem qui</p>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                <div class="single-why-choose">
                    <div class="icon">
                        <i class="flaticon-006-wallet"></i>
                    </div>
                    <div class="title">
                        <h3>Different Wallet</h3>
                    </div>
                    <p>Voluptatibus, fugiat. Perspiciatis libero saepe ullam rem qui</p>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                <div class="single-why-choose">
                    <div class="icon">
                        <i class="flaticon-2991852"></i>
                    </div>
                    <div class="title">
                        <h3>Multiple Currency</h3>
                    </div>
                    <p>Voluptatibus, fugiat. Perspiciatis libero saepe ullam rem qui</p>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                <div class="single-why-choose">
                    <div class="icon">
                        <i class="flaticon-2991872"></i>
                    </div>
                    <div class="title">
                        <h3>Low Rate</h3>
                    </div>
                    <p>Voluptatibus, fugiat. Perspiciatis libero saepe ullam rem qui</p>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                <div class="single-why-choose">
                    <div class="icon">
                        <i class="flaticon-2991871"></i>
                    </div>
                    <div class="title">
                        <h3>Different Wallet</h3>
                    </div>
                    <p>Voluptatibus, fugiat. Perspiciatis libero saepe ullam rem qui</p>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                <div class="single-why-choose">
                    <div class="icon">
                        <i class="flaticon-023-line-chart"></i>
                    </div>
                    <div class="title">
                        <h3>Multiple Currency</h3>
                    </div>
                    <p>Voluptatibus, fugiat. Perspiciatis libero saepe ullam rem qui</p>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                <div class="single-why-choose">
                    <div class="icon">
                        <i class="flaticon-030-carrier"></i>
                    </div>
                    <div class="title">
                        <h3>Low Rate</h3>
                    </div>
                    <p>Voluptatibus, fugiat. Perspiciatis libero saepe ullam rem qui</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!--/Why Choose Us-->

<!--About Area-->
<section class="section-padding-2 blue-bg-2 dots-after">
    <div class="container">
        <div class="row">
            <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12 cl-white mb-30">
                <div class="section-title">
                    <h4><span>Top</span>What we do?</h4>
                    <h2>There is what we do</h2>
                </div>
                <p class="cl-white-light">Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum, veniam.
                    Quisquam dicta, atque nemo error impedit necessitatibus, incidunt rem architecto optio facilis aut
                    illo labore numquam et soluta quo. Ratione! Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Exercitationem aliquam amet nulla eius voluptates rem numquam ipsa, dolores ratione soluta quo
                    tempora, quis sit. Amet fugit autem nobis officiis eius.</p>
                <div class="row mb-20">
                    <div class="col-xl-6 col-md-12">
                        <h5 class="mb-10">Mission</h5>
                        <p class="cl-white-light">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Magni,
                            velit. Facere pariatur iste cupiditate ea, animi nihil dolor iusto amet error libero aut
                            deleniti quas laboriosam accusamus, unde quisquam dolorem. Lorem ipsum, dolor sit amet
                            consectetur adipisicing elit</p>
                    </div>
                    <div class="col-xl-6 col-md-12">
                        <h5 class="mb-10">Vission</h5>
                        <p class="cl-white-light">Inventore? Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                            Repellat, inventore quae laudantium accusamus adipisci sequi veritatis saepe aspernatur
                            libero fuga illo voluptas nihil. Sequi, quis aspernatur! Deserunt pariatur quas reiciendis!
                        </p>
                    </div>
                </div>

            </div>
            <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                <div class="about-img">
                    <img src="assets/images/about.jpg" alt="">
                    <a href="https://www.youtube.com/watch?v=DJyxwIGdl8Y" class="video-popup"><i
                            class="fa fa-play"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!--/About Area-->

<!--Solutions Section-->
<section class="section-padding-2 blue-bg">
    <div class="container">
        <div class="row">
            <div class="col-xl-8 col-md-12">
                <div class="section-title">
                    <h4><span>new</span>Anyone can use</h4>
                    <h2>Solutions for</h2>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                <div class="single-product">
                    <div class="product-pro-img">
                        <img src="assets/images/company/mtn.png" alt="">
                    </div>
                    <div class="product-pro-title">
                        <h3><a href="">MTN</a></h3>
                        <!-- <p>Dolorem accusamus maiores hic assumenda illum perferendis</p> -->
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                <div class="single-product">
                    <div class="product-pro-img">
                        <img src="assets/images/company/paypal.jpeg" alt="">
                    </div>
                    <div class="product-pro-title">
                        <h3><a href="">Paypal</a></h3>
                        <!-- <p>Dolorem accusamus maiores hic assumenda illum perferendis</p> -->
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                <div class="single-product">
                    <div class="product-pro-img">
                        <img src="assets/images/company/airtel.png" alt="">
                    </div>
                    <div class="product-pro-title">
                        <h3><a href="">Airtel</a></h3>
                        <!-- <p>Dolorem accusamus maiores hic assumenda illum perferendis</p> -->
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                <div class="single-product">
                    <div class="product-pro-img">
                        <img src="assets/images/company/scrill.png" alt="">
                    </div>
                    <div class="product-pro-title">
                        <h3><a href="">Scrill</a></h3>
                        <!-- <p>Dolorem accusamus maiores hic assumenda illum perferendis</p> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--/Solutions Section-->

<!-- What we do -->
<section class="section-padding-2 blue-bg-2">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-7 col-lg-7 col-md-12 centered">
                <div class="section-title">
                    <h4><span>new</span> What we're doing?</h4>
                    <h2>Follow this stepts</h2>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                <div class="single-what-we-do">
                    <div class="icon">
                        <i class="ti-ruler-pencil"></i>
                    </div>
                    <div class="title">
                        <h3>Send Money</h3>
                    </div>
                    <p>Quae omnis et alias quisquam quod? Laborum accusamus sed exercitationem quod</p>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                <div class="single-what-we-do">
                    <div class="icon">
                        <i class="ti-layout"></i>
                    </div>
                    <div class="title">
                        <h3>Currency Exchange</h3>
                    </div>
                    <p>Quae omnis et alias quisquam quod? Laborum accusamus sed exercitationem quod</p>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                <div class="single-what-we-do">
                    <div class="icon">
                        <i class="ti-cup"></i>
                    </div>
                    <div class="title">
                        <h3>Widthdraw</h3>
                    </div>
                    <p>Quae omnis et alias quisquam quod? Laborum accusamus sed exercitationem quod</p>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                <div class="single-what-we-do">
                    <div class="icon">
                        <i class="ti-package"></i>
                    </div>
                    <div class="title">
                        <h3>Deposit</h3>
                    </div>
                    <p>Quae omnis et alias quisquam quod? Laborum accusamus sed exercitationem quod</p>
                </div>
            </div>
        </div>
    </div>
</section><!-- /What we do -->

<!--Call to action-->
<section class="section-padding-2 blue-bg-2 dots-before">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-8 col-lg-8 col-md-8 col-sm-6 mb-30">
                <div class="call-to-action">
                    <h4>Get the bonus up to</h4>
                    <h1>$199<span>for</span></h1>
                    <div class="cat-btns">
                        <a href="" class="bttn-mid btn-fill">Signup now</a>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                <div class="last-winners">
                    <h4>Lastest bonus winners</h4>
                    <div class="single-winner">
                        <div class="thumb">P</div>
                        <div class="content">
                            <p>Potrik won <span>$199</span> from reefer his friends</p>
                        </div>
                    </div>
                    <div class="single-winner">
                        <div class="thumb">K</div>
                        <div class="content">
                            <p>Kevin won <span>$199</span> from reefer his friends</p>
                        </div>
                    </div>
                    <div class="single-winner">
                        <div class="thumb">S</div>
                        <div class="content">
                            <p>Soul won <span>$199</span> from reefer his friends</p>
                        </div>
                    </div>
                    <div class="single-winner">
                        <div class="thumb">Y</div>
                        <div class="content">
                            <p>York won <span>$199</span> from reefer his friends</p>
                        </div>
                    </div>
                    <div class="single-winner">
                        <div class="thumb">R</div>
                        <div class="content">
                            <p>Rick won <span>$199</span> from reefer his friends</p>
                        </div>
                    </div>
                    <div class="single-winner">
                        <div class="thumb">T</div>
                        <div class="content">
                            <p>Toress won <span>$199</span> from reefer his friends</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--/Call to action-->

<!-- New User Area -->
<section class="section-padding blue-bg dots-after">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-6 centered">
                <div class="section-title cl-white">
                    <h4><span>New</span>Top Users</h4>
                    <h2>Best Users</h2>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-xl-1 col-lg-1 col-md-2 col-sm-3 col-4">
                <div class="single-new-user" data-toggle="tooltip" data-placement="top" title=""
                    data-original-title="John Harison">
                    <img src="assets/images/new-user/1.jpg" alt="">
                </div>
            </div>
            <div class="col-xl-1 col-lg-1 col-md-2 col-sm-3 col-4">
                <div class="single-new-user" data-toggle="tooltip" data-placement="top" title=""
                    data-original-title="Verna Valdez">
                    <img src="assets/images/new-user/2.jpg" alt="">
                </div>
            </div>
            <div class="col-xl-1 col-lg-1 col-md-2 col-sm-3 col-4">
                <div class="single-new-user" data-toggle="tooltip" data-placement="top" title=""
                    data-original-title="Leticia Romero">
                    <img src="assets/images/new-user/3.jpg" alt="">
                </div>
            </div>
            <div class="col-xl-1 col-lg-1 col-md-2 col-sm-3 col-4">
                <div class="single-new-user" data-toggle="tooltip" data-placement="top" title=""
                    data-original-title="Erica Rodriquez">
                    <img src="assets/images/new-user/4.jpg" alt="">
                </div>
            </div>
            <div class="col-xl-1 col-lg-1 col-md-2 col-sm-3 col-4">
                <div class="single-new-user" data-toggle="tooltip" data-placement="top" title=""
                    data-original-title="Nathan Murray">
                    <img src="assets/images/new-user/5.jpg" alt="">
                </div>
            </div>
            <div class="col-xl-1 col-lg-1 col-md-2 col-sm-3 col-4">
                <div class="single-new-user" data-toggle="tooltip" data-placement="top" title=""
                    data-original-title="Kerry Dixon">
                    <img src="assets/images/new-user/6.jpg" alt="">
                </div>
            </div>
            <div class="col-xl-1 col-lg-1 col-md-2 col-sm-3 col-4">
                <div class="single-new-user" data-toggle="tooltip" data-placement="top" title=""
                    data-original-title="Ollie Fleming">
                    <img src="assets/images/new-user/7.jpg" alt="">
                </div>
            </div>
            <div class="col-xl-1 col-lg-1 col-md-2 col-sm-3 col-4">
                <div class="single-new-user" data-toggle="tooltip" data-placement="top" title=""
                    data-original-title="Lynda Gregory">
                    <img src="assets/images/new-user/8.jpg" alt="">
                </div>
            </div>
            <div class="col-xl-1 col-lg-1 col-md-2 col-sm-3 col-4">
                <div class="single-new-user" data-toggle="tooltip" data-placement="top" title=""
                    data-original-title="Leigh Bates">
                    <img src="assets/images/new-user/9.jpg" alt="">
                </div>
            </div>
            <div class="col-xl-1 col-lg-1 col-md-2 col-sm-3 col-4">
                <div class="single-new-user" data-toggle="tooltip" data-placement="top" title=""
                    data-original-title="Wanda Boone">
                    <img src="assets/images/new-user/10.jpg" alt="">
                </div>
            </div>
            <div class="col-xl-1 col-lg-1 col-md-2 col-sm-3 col-4">
                <div class="single-new-user" data-toggle="tooltip" data-placement="top" title=""
                    data-original-title="Cristina James">
                    <img src="assets/images/new-user/11.jpg" alt="">
                </div>
            </div>
            <div class="col-xl-1 col-lg-1 col-md-2 col-sm-3 col-4">
                <div class="single-new-user" data-toggle="tooltip" data-placement="top" title=""
                    data-original-title="Emilio Fuller">
                    <img src="assets/images/new-user/12.jpg" alt="">
                </div>
            </div>
            <div class="col-xl-1 col-lg-1 col-md-2 col-sm-3 col-4">
                <div class="single-new-user" data-toggle="tooltip" data-placement="top" title=""
                    data-original-title="John Harison">
                    <img src="assets/images/new-user/10.jpg" alt="">
                </div>
            </div>
            <div class="col-xl-1 col-lg-1 col-md-2 col-sm-3 col-4">
                <div class="single-new-user" data-toggle="tooltip" data-placement="top" title=""
                    data-original-title="Verna Valdez">
                    <img src="assets/images/new-user/12.jpg" alt="">
                </div>
            </div>
            <div class="col-xl-1 col-lg-1 col-md-2 col-sm-3 col-4">
                <div class="single-new-user" data-toggle="tooltip" data-placement="top" title=""
                    data-original-title="Leticia Romero">
                    <img src="assets/images/new-user/11.jpg" alt="">
                </div>
            </div>
            <div class="col-xl-1 col-lg-1 col-md-2 col-sm-3 col-4">
                <div class="single-new-user" data-toggle="tooltip" data-placement="top" title=""
                    data-original-title="Erica Rodriquez">
                    <img src="assets/images/new-user/2.jpg" alt="">
                </div>
            </div>
            <div class="col-xl-1 col-lg-1 col-md-2 col-sm-3 col-4">
                <div class="single-new-user" data-toggle="tooltip" data-placement="top" title=""
                    data-original-title="Nathan Murray">
                    <img src="assets/images/new-user/3.jpg" alt="">
                </div>
            </div>
            <div class="col-xl-1 col-lg-1 col-md-2 col-sm-3 col-4">
                <div class="single-new-user" data-toggle="tooltip" data-placement="top" title=""
                    data-original-title="Kerry Dixon">
                    <img src="assets/images/new-user/1.jpg" alt="">
                </div>
            </div>
            <div class="col-xl-1 col-lg-1 col-md-2 col-sm-3 col-4">
                <div class="single-new-user" data-toggle="tooltip" data-placement="top" title=""
                    data-original-title="Ollie Fleming">
                    <img src="assets/images/new-user/4.jpg" alt="">
                </div>
            </div>
            <div class="col-xl-1 col-lg-1 col-md-2 col-sm-3 col-4">
                <div class="single-new-user" data-toggle="tooltip" data-placement="top" title=""
                    data-original-title="Lynda Gregory">
                    <img src="assets/images/new-user/6.jpg" alt="">
                </div>
            </div>
            <div class="col-xl-1 col-lg-1 col-md-2 col-sm-3 col-4">
                <div class="single-new-user" data-toggle="tooltip" data-placement="top" title=""
                    data-original-title="Leigh Bates">
                    <img src="assets/images/new-user/5.jpg" alt="">
                </div>
            </div>
            <div class="col-xl-1 col-lg-1 col-md-2 col-sm-3 col-4">
                <div class="single-new-user" data-toggle="tooltip" data-placement="top" title=""
                    data-original-title="Wanda Boone">
                    <img src="assets/images/new-user/7.jpg" alt="">
                </div>
            </div>
            <div class="col-xl-1 col-lg-1 col-md-2 col-sm-3 col-4">
                <div class="single-new-user" data-toggle="tooltip" data-placement="top" title=""
                    data-original-title="Cristina James">
                    <img src="assets/images/new-user/9.jpg" alt="">
                </div>
            </div>
            <div class="col-xl-1 col-lg-1 col-md-2 col-sm-3 col-4">
                <div class="single-new-user" data-toggle="tooltip" data-placement="top" title=""
                    data-original-title="Emilio Fuller">
                    <img src="assets/images/new-user/8.jpg" alt="">
                </div>
            </div>
            <div class="col-xl-6 centered">
                <a href="javascript:void(0);" (click)="redirectRegister()" class="bttn-small btn-fill">Signup now</a>
            </div>
        </div>
    </div>
</section><!-- New User Area -->

<!-- Counter Area -->
<section class="section-padding-2 gradient-overlay"
    style="background: url('assets/images/banner/3.jpg') no-repeat fixed;">
    <div class="container">
        <div class="row">
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                <div class="single-new-counter">
                    <i class="flaticon-011-share"></i>
                    <h3 class="count">5246</h3>
                    <h5>Lines of code</h5>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                <div class="single-new-counter">
                    <i class="flaticon-028-coins"></i>
                    <h3 class="count">2016</h3>
                    <h5>Cup of coffee</h5>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                <div class="single-new-counter">
                    <i class="flaticon-026-hierarchy"></i>
                    <h3 class="count">68</h3>
                    <h5>Project done</h5>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                <div class="single-new-counter">
                    <i class="flaticon-2991877"></i>
                    <h3>$<span class="count">11.5</span>M</h3>
                    <h5>Daily Transaction</h5>
                </div>
            </div>
        </div>
    </div>
</section><!-- /Counter Area -->

<!-- Testimonials -->
<section class="section-padding blue-bg">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-6 col-lg-6 col-md-12 centered">
                <div class="section-title">
                    <h4><span>new</span> What users say about us</h4>
                    <h2>You will get confident to start using us</h2>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-xl-8 col-lg-8 col-md-12 centered">
                <div class="testimonials owl-carousel">
                    <div class="single-testimonial">
                        <div class="text">
                            Rerum vitae commodi dicta error quaerat debitis magnam numquam earum! In vel dolorum
                            repudiandae aspernatur praesentium incidunt molestias enim quis sapiente perspiciatis
                        </div>
                        <div class="person">
                            <img src="assets/images/reviewer/1.jpg" alt="">
                            <h4>Nick Herison</h4>
                            <p>Freelancer</p>
                        </div>
                    </div>
                    <div class="single-testimonial">
                        <div class="text">
                            Rerum vitae commodi dicta error quaerat debitis magnam numquam earum! In vel dolorum
                            repudiandae aspernatur praesentium incidunt molestias enim quis sapiente perspiciatis
                        </div>
                        <div class="person">
                            <img src="assets/images/reviewer/2.jpg" alt="">
                            <h4>Nick Herison</h4>
                            <p>Freelancer</p>
                        </div>
                    </div>
                    <div class="single-testimonial">
                        <div class="text">
                            Rerum vitae commodi dicta error quaerat debitis magnam numquam earum! In vel dolorum
                            repudiandae aspernatur praesentium incidunt molestias enim quis sapiente perspiciatis
                        </div>
                        <div class="person">
                            <img src="assets/images/reviewer/3.jpg" alt="">
                            <h4>Nick Herison</h4>
                            <p>Freelancer</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section><!-- /Testimonials -->

<!-- Mobile App -->
<section class="section-padding-2 blue-bg">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-8 col-lg-8 col-md-12 centered">
                <div class="section-title">
                    <h4><span>New</span>Mobile app</h4>
                    <h2>Get your Mobile now</h2>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 mb-30">
                <div class="app-content">
                    <div class="single-app-content">
                        <h3>Use to pay bill</h3>
                        <p>Necessitatibus iure eum temporibus quo expedita officiis, amet, distinctio accusantium
                            tempora saepe</p>
                    </div>
                    <div class="single-app-content">
                        <h3>Monitor live</h3>
                        <p>Necessitatibus iure eum temporibus quo expedita officiis, amet, distinctio accusantium
                            tempora saepe</p>
                    </div>
                    <div class="single-app-content">
                        <h3>Instantly Transaction</h3>
                        <p>Necessitatibus iure eum temporibus quo expedita officiis, amet, distinctio accusantium
                            tempora saepe</p>
                    </div>
                    <div class="single-app-content">
                        <h3>Reliability</h3>
                        <p>Necessitatibus iure eum temporibus quo expedita officiis, amet, distinctio accusantium
                            tempora saepe</p>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 mb-30">
                <div class="app-content">
                    <div class="app-content-slider owl-carousel">
                        <img class="middle-img" src="assets/images/mobile-4.png" alt="">
                        <img class="middle-img" src="assets/images/mobile-5.png" alt="">
                        <img class="middle-img" src="assets/images/mobile-2.png" alt="">
                        <img class="middle-img" src="assets/images/mobile-3.png" alt="">
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 mb-30">
                <div class="app-content">
                    <div class="single-app-content">
                        <h3>Easy to manage</h3>
                        <p>Necessitatibus iure eum temporibus quo expedita officiis, amet, distinctio accusantium
                            tempora saepe</p>
                    </div>
                    <div class="single-app-content">
                        <h3>See Statistics</h3>
                        <p>Necessitatibus iure eum temporibus quo expedita officiis, amet, distinctio accusantium
                            tempora saepe</p>
                    </div>
                    <div class="single-app-content">
                        <h3>Use to pay bill</h3>
                        <p>Necessitatibus iure eum temporibus quo expedita officiis, amet, distinctio accusantium
                            tempora saepe</p>
                    </div>
                    <div class="single-app-content">
                        <h3>Use to pay bill</h3>
                        <p>Necessitatibus iure eum temporibus quo expedita officiis, amet, distinctio accusantium
                            tempora saepe</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section><!-- /Mobile App -->

<!-- Team Section -->
<section class="section-padding blue-bg-2">
    <div class="container">
        <div class="row">
            <div class="col-xl-12">
                <div class="section-title">
                    <h4><span>new</span> Team</h4>
                    <h2>Our Team</h2>
                </div>
            </div>
        </div>
        <div id="team-carousel" class="team-carousel owl-carousel">
            <div class="team-box">
                <img src="assets/images/team/1.jpg" alt="team">
                <div class="hover">
                    <h4>Joy Burton</h4>
                    <p>CEO and Founder</p>
                    <ul class="team-social">
                        <li><a href="#"><i class="fa fa-medium"></i></a></li>
                        <li><a href="#"><i class="fa fa-github"></i></a></li>
                        <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                        <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                        <li><a href="#"><i class="fa fa-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="team-box">
                <img src="assets/images/team/2.jpg" alt="team">
                <div class="hover">
                    <h4>Mega Rox</h4>
                    <p>CTO</p>
                    <ul class="team-social">
                        <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                        <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                        <li><a href="#"><i class="fa fa-instagram"></i></a></li>
                        <li><a href="#"><i class="fa fa-pinterest"></i></a></li>
                        <li><a href="#"><i class="fa fa-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="team-box">
                <img src="assets/images/team/3.jpg" alt="team">
                <div class="hover">
                    <h4>Lever Jhon</h4>
                    <p>Lead Engineer</p>
                    <ul class="team-social">
                        <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                        <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                        <li><a href="#"><i class="fa fa-instagram"></i></a></li>
                        <li><a href="#"><i class="fa fa-pinterest"></i></a></li>
                        <li><a href="#"><i class="fa fa-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="team-box">
                <img src="assets/images/team/4.jpg" alt="team">
                <div class="hover">
                    <h4>Naven Kaper</h4>
                    <p>Support Engineer</p>
                    <ul class="team-social">
                        <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                        <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                        <li><a href="#"><i class="fa fa-instagram"></i></a></li>
                        <li><a href="#"><i class="fa fa-pinterest"></i></a></li>
                        <li><a href="#"><i class="fa fa-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="team-box">
                <img src="assets/images/team/2.jpg" alt="team">
                <div class="hover">
                    <h4>Royal Cott</h4>
                    <p>Marketer</p>
                    <ul class="team-social">
                        <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                        <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                        <li><a href="#"><i class="fa fa-instagram"></i></a></li>
                        <li><a href="#"><i class="fa fa-pinterest"></i></a></li>
                        <li><a href="#"><i class="fa fa-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section><!-- /Team Section -->

<!-- Blog Section -->
<section class="section-padding blue-bg">
    <div class="container">
        <div class="row">
            <div class="col-xl-12 centered">
                <div class="section-title">
                    <h4><span>new</span> Blog section</h4>
                    <h2>Latest News</h2>
                </div>
            </div>
        </div>
        <!-- News Carousel -->
        <div class="news-carousel owl-carousel">
            <!-- News Block -->
            <div class="news-block">
                <div class="inner-box">
                    <ul class="info">
                        <li class="day">14th <span>Oct</span></li>
                        <li class="comments"><a href="">10<span class="ti-comments"></span></a></li>
                    </ul>
                    <div class="content">
                        <img src="assets/images/blog/1.jpg" alt="" />
                        <div class="author">Category: <a href="">Wallet</a> by <a href="#">Paylo</a></div>
                        <h3><a href="#">Learn how to make Transaction</a></h3>
                        <div class="text">There are many variations of passages of Lorem Ipsum available, but the majority have..</div>
                        <div class="link-box"><a href="#">Read More</a></div>
                    </div>
                </div>
            </div>

            <!-- News Block -->
            <div class="news-block">
                <div class="inner-box">
                    <ul class="info">
                        <li class="day">14th <span>Oct</span></li>
                        <li class="comments"><a href="">10<span class="ti-comments"></span></a></li>
                    </ul>
                    <div class="content">
                        <img src="assets/images/blog/2.jpg" alt="" />
                        <div class="author">Category: <a href="">Wallet</a> by <a href="#">Paylo</a></div>
                        <h3><a href="#">Making the Transaction free and secure</a></h3>
                        <div class="text">There are many variations of passages of Lorem Ipsum available, but the majority have..</div>
                        <div class="link-box"><a href="#">Read More</a></div>
                    </div>
                </div>
            </div>

            <!-- News Block -->
            <div class="news-block">
                <div class="inner-box">
                    <ul class="info">
                        <li class="day">14th <span>Oct</span></li>
                        <li class="comments"><a href="">10<span class="ti-comments"></span></a></li>
                    </ul>
                    <div class="content">
                        <img src="assets/images/blog/3.jpg" alt="" />
                        <div class="author">Category: <a href="">Wallet</a> by <a href="#">Paylo</a></div>
                        <h3><a href="#">Your Transaction should perfect</a></h3>
                        <div class="text">There are many variations of passages of Lorem Ipsum available, but the majority have..</div>
                        <div class="link-box"><a href="#">Read More</a></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!--Payments Area-->
<section class="section-padding-3 blue-bg-2 dots-after">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-6">
                <div class="single-payments">
                    <a href=""><img src="assets/images/payments/1.png" alt=""></a>
                </div>
            </div>
            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-6">
                <div class="single-payments">
                    <a href=""><img src="assets/images/payments/2.png" alt=""></a>
                </div>
            </div>
            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-6">
                <div class="single-payments">
                    <a href=""><img src="assets/images/payments/3.png" alt=""></a>
                </div>
            </div>
            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-6">
                <div class="single-payments">
                    <a href=""><img src="assets/images/payments/4.png" alt=""></a>
                </div>
            </div>
            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-6">
                <div class="single-payments">
                    <a href=""><img src="assets/images/payments/5.png" alt=""></a>
                </div>
            </div>
            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-6">
                <div class="single-payments">
                    <a href=""><img src="assets/images/payments/6.png" alt=""></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!--/Payments Area-->

<!--newslatter-->
<section class="section-padding blue-bg">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="call-to-action centered">
                    <div class="section-title">
                        <h4><span>Top</span>Stay updated</h4>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-xl-6 col-lg-6 col-sm-12">
                            <div class="newslatter">
                                <form action="#">
                                    <input type="email" placeholder="Email Address" required>
                                    <button type="submit">Subscribe</button>
                                </form>
                                <div class="social">
                                    <a href=""><i class="fa fa-facebook"></i></a>
                                    <a href=""><i class="fa fa-instagram"></i></a>
                                    <a href=""><i class="fa fa-twitter"></i></a>
                                    <a href=""><i class="fa fa-pinterest"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--/newslatter-->