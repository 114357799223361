<app-header-component></app-header-component>
<ngx-spinner></ngx-spinner>

<section class="section-padding-sm-2 blue-bg">
    <div class="container">
        <div class="row">

        </div>
    </div>
</section>
<!--Dashboard top-->


<!--Dashboard Bottom-->
<section class="section-padding-sm-2 blue-bg-2">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-12 col-lg-12 col-md-12 mb-20">
                <div class="card mb-30">
                    <div class="card-header">
                        Admin Profile Info <a href="" data-toggle="tooltip" data-placement="top" title=""
                            data-original-title="Edit Profile info"><i class="ti-pencil"></i></a>
                    </div>
                    <div class="card-body">
                        <div class="profile-card-body">

                            <div class="row">
                                <div class="col-sm-3 col-3">
                                    <div class="left-info text-right">
                                        <p>Payworld User Name</p>
                                        <p>Payworld Password</p>
                                        <p>Airtel Merchant ID</p>
                                        <p>Mtn Merchant ID</p>
                                        <p>Confirmation URL</p>
                                        <p> Commission (Same Channel)</p>
                                        <p>Commission (Cross Channel)</p>
                                    </div>
                                </div>
                                <div class="col-sm-9 col-9">
                                    <div class="right-info">
                                        <p>{{payworldName}}</p>
                                        <p>{{payworldPassword}}</p>
                                        <p>{{airtelmerchantId}}</p>
                                        <p>{{mtnmerchantId}}</p>
                                        <p>{{url}}</p>
                                        <p>{{commission_s}}</p>
                                        <p>{{commission_c}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 mb-20">
                <div class="card mb-30">
                    <form [formGroup]="adminProfileForm" novalidate (submit)="onSubmit()" #myform="ngForm" class="hero-form">
                       
                        <div class="card-header">
                            Change Admin Password <a href="" data-toggle="tooltip" data-placement="top" title=""
                                data-original-title="Edit Profile info"><i class="ti-pencil"></i></a>
                        </div>
                        <div class="card-body">
                            <div class="profile-card-body">

                                <div class="row">
                                    <div class="col-sm-3 col-3">
                                        <div class="left-info text-right">
                                            <p>Enter Old Password</p>
                                            <p>Enter New Password</p>
                                        </div>
                                    </div>
                                    <div class="col-sm-9 col-9">
                                        <div class="right-info">
                                            <!-- <p></p> -->
                                            <input type="password" class="form-control" placeholder="Enter old password"
                                                aria-describedby="inputGroupPrepend2" formControlName="password">
                                            <input type="password" class="form-control" placeholder="Enter old password"
                                                aria-describedby="inputGroupPrepend2" formControlName="newpass">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type="submit" class="bttn-mid w-100">Submit</button>

                    </form>
                </div>
            </div>



        </div>
    </div>
</section>
<!--Dashboard Bottom-->