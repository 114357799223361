import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators, NgForm, FormGroupDirective, FormBuilder } from '@angular/forms';
import { UserService } from '../user.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-log-in',
  templateUrl: './log-in.component.html',
  styleUrls: ['./log-in.component.css']
})
export class LogInComponent implements OnInit {


  logInFormData: any;
  constructor(private formBuilder: FormBuilder, private user: UserService, 
    private router: Router, private spinner: NgxSpinnerService,private toastr: ToastrService) { }

  LogInForm: FormGroup;

  ngOnInit(): void {
    this.spinner.hide();
    let data = localStorage.getItem('loggedInUserData');
    if (data != undefined || data != null) {
      this.router.navigate(['/dashboard'])
    }
    else {
      this.LogInForm = this.formBuilder.group({
        phone: ['', [Validators.required]],
        password: ['', Validators.required]
      })
    }
  }

  onSubmit() {
    if (this.LogInForm.valid) {
      this.logInFormData = this.LogInForm.value,
        // console.log(this.logInFormData);
        this.spinner.show();
      this.user.UserLogInPost(this.logInFormData).subscribe(data => {
        let responseData = data
        if (data['msg'] == "Login Successfull") {
          localStorage.setItem('loggedInUserData', JSON.stringify(data['data']))
          this.router.navigate(['/dashboard']);
        }
        else {
        //  alert(data["msg"]);
        this.toastr.error(data["msg"]);
        }
        this.spinner.hide();
      })
    }
  }
  redirectRegister() {
    this.router.navigate(['/registration']);
  }
}
