import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pay-now',
  templateUrl: './pay-now.component.html',
  styleUrls: ['./pay-now.component.css']
})
export class PayNowComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
