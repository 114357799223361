<section class="breadcrumb-area gradient-overlay" style="background: url('assets/images/banner/3.jpg');">
    <div class="container">
        <div class="row">
            <div class="col-xl-12">
                <div class="site-breadcrumb">
                    <h2>Register now</h2>
                </div>
            </div>
        </div>
    </div>
</section><!--/breadcrumb area-->

<!--Signup Section -->
<section class="section-padding blue-bg shaded-bg">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 centered">
                <div class="section-title cl-white">
                    <h4><span>New</span>Create account</h4>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                <div class="site-form mb-30">
                    <form  [formGroup]="userRegistrationForm" novalidate (submit)="onSubmit()" #myform="ngForm">
                        <div class="row">
                            <div class="col-xl-6 col-lg-6 col-sm-6">
                                <input type="text" placeholder="First Name" formControlName="firstname">
                                <div *ngIf= "userRegistrationForm.get('firstname').hasError('required') && myform.submitted" style="color: red;">*Enter Your  Firstname!</div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-sm-6">
                                <input type="text" placeholder="Last Name" formControlName="lastname">
                                <div *ngIf= "userRegistrationForm.get('lastname').hasError('required') && myform.submitted" style="color: red;">*Enter Your Lastname!  </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-sm-6">
                                <input type="email" placeholder="Email" formControlName="email">
                                <div *ngIf= "userRegistrationForm.get('email').hasError('required') && myform.submitted" style="color: red;">*Enter Your Email Address!  </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-sm-6">
                                <div class="row">
                                    <div class="col-md-3" style="padding-right:0px">
                                        <span style="height: 55px;padding-left: 25px; background: #003754; color: #ffffff" class="input-group-text" id="inputGroupPrepend">242</span>    
                                    </div>
                                    <div class="col-md-9" style="padding-left:0px">
                                        <input type="text" placeholder="Phone" formControlName="phone">
                                    </div>
                                </div>
                                <div *ngIf= "userRegistrationForm.get('phone').hasError('required') && myform.submitted" style="color: red;">*Enter Your Phone Number!  </div>
                            </div>
                            <div class="col-xl-12 col-lg-12 col-sm-12">
                                <select class="form-control" formControlName="channelId" style="margin-bottom: 15px; background: #003754; color: #ffffff;">
                                    <option value="" disabled selected>Select Merchant</option>
                                    <option value="2">MTN</option>
                                    <option value="1">Airtel</option>
                                </select>
                            </div>
                            <div *ngIf= "userRegistrationForm.get('channelId').hasError('required') && myform.submitted" style="color: red;">*Please Select Merchant!  </div>
                            <div class="col-xl-6 col-lg-6 col-sm-6">
                                <input type="password" placeholder="Password" formControlName="password">
                                <div *ngIf= "userRegistrationForm.get('password').hasError('required') && myform.submitted" style="color: red;">*Enter Your password!  </div>
                                <div *ngIf="userRegistrationForm.get('password').hasError('minlength')&& myform.submitted" style="color: red;">Password must be 8 character.</div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-sm-6">
                                <input type="password" placeholder="Retype Password" formControlName="confirmPassword">
                                <div *ngIf= "userRegistrationForm.get('confirmPassword').hasError('required') && myform.submitted" style="color: red;">*Enter confirm password!  </div>
                                <!-- <div *ngIf="userRegistrationForm.confirmPassword.errors.confirmedValidator">Password and Confirm Password did not match.</div> -->
                            </div>
                            <div class="col-xl-12 col-lg-12 col-sm-12">
                                <button type="submit" class="bttn-mid btn-fill w-100">Register now</button>
                            </div>
                            <div class="col-xl-12 col-lg-12 col-sm-12">
                                <div class="extra-links">
                                    <a href="javascript:void(0);" (click)="redirectLogin()" class="cl-white">Login Account?</a>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section><!--/Signup Section-->