import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, NgForm, FormGroupDirective, FormBuilder } from '@angular/forms';

import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../user.service';

@Component({
  selector: 'app-admin-profile',
  templateUrl: './admin-profile.component.html',
  styleUrls: ['./admin-profile.component.css']
})
export class AdminProfileComponent implements OnInit {

  constructor(private user: UserService, private formBuilder: FormBuilder, private router: Router, private toastr: ToastrService,
    private spinner: NgxSpinnerService) { }
  adminProfileForm: FormGroup;
  payworldName: any;
  payworldPassword:any;
  mtnmerchantId:any;
  commission_s:any;
  commission_c:any;
  url:any;
  airtelmerchantId: any;
  tillno:any;
  ngOnInit(): void {
    const statusData = new FormData();
    let userData = localStorage.getItem('adminUserData');
    if (userData != null || userData != undefined) {


      const data = JSON.parse(userData);

      statusData.append('token', data[0]['token']);
      this.adminProfileForm = this.formBuilder.group({
        password: ['', Validators.required],
        newpass: ['', Validators.required],

      });
      this.user.adminPRofiledetails(statusData).subscribe(paymentData => {
        this.payworldName = paymentData["details"][0]["payworld_email"];
        this.payworldPassword = paymentData["details"][0]["payworld_password"];
        this.mtnmerchantId = paymentData["details"][0]["mtnMerchantId"];
        this.commission_s = paymentData["details"][0]["same_c"];
        this.commission_c = paymentData["details"][0]["cross_c"];
        this.url = paymentData["details"][0]["confirmationUrl"];
        this.airtelmerchantId = paymentData["details"][0]["airtelMerchantId"];
        this.tillno = paymentData["details"][0]["tillNumber"];

      });
    }
    else {
      this.router.navigate(['/admin-login'])
    }
  }
  onSubmit() {
    if (this.adminProfileForm.valid) {
      let userData = localStorage.getItem('adminUserData');
      const data = JSON.parse(userData);

      //  statusData.append('token', data[0]['token']);
      this.adminProfileForm.value['token'] = data[0]['token']
      this.spinner.show();
      this.user.adminpass(this.adminProfileForm.value).subscribe(data => {
        let responseData = data;
        if (data['status'] == "0") {
          this.toastr.error(data['msg']);
          this.spinner.hide();
        }
        else {
          this.toastr.success(data['msg'])
          this.spinner.hide();
          this.adminProfileForm.reset();
        }

      });
    }
  }
}

