import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, NgForm, FormGroupDirective, FormBuilder } from '@angular/forms';
import { UserService } from '../user.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.css']
})
export class AdminLoginComponent implements OnInit {

  AdminlogInFormData: any;
  constructor(private formBuilder: FormBuilder, private user: UserService, private router: Router, 
    private spinner: NgxSpinnerService, private toastr: ToastrService) { }

  adminLogInForm: FormGroup;
  ngOnInit(): void {
    // let data = localStorage.getItem('loggedInUserData');
    // if (data != undefined || data != null) {
    //   this.router.navigate(['/user-list'])
    // }
    // else {
      this.adminLogInForm = this.formBuilder.group({
        username: ['', [Validators.required]],
        password: ['', Validators.required]
       })
      // }
    }

  onSubmit() {
    if (this.adminLogInForm.valid) {
      let userData = localStorage.getItem('loggedInUserData');
      if (userData == undefined || userData == null) {
        this.toastr.error('Please try after login');
        this.router.navigate(['/admin-login']);
      }
      this.spinner.show();
      this.AdminlogInFormData = this.adminLogInForm.value,
        //  console.log(this.AdminlogInFormData);
        this.user.adminLogInPost(this.AdminlogInFormData).subscribe(data => {
          let responseData = data
          if (data['msg'] == "Login Successful") {
            localStorage.setItem('adminUserData', JSON.stringify(data['data']))
            this.router.navigate(['/user-list']);
          }
          else {
            //   alert(data["msg"]);
            this.toastr.error(data["msg"]);
          }
        })
      this.spinner.hide();
    }
  }
}
