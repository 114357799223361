import { Component, OnInit } from '@angular/core';
import { FormGroup , FormControl , Validators,NgForm, FormGroupDirective,FormBuilder } from '@angular/forms';
import {Router} from '@angular/router';
import  {UserService} from '../user.service'

@Component({
  selector: 'app-merchant-add',
  templateUrl: './merchant-add.component.html',
  styleUrls: ['./merchant-add.component.css']
})
export class MerchantAddComponent implements OnInit {

  constructor(public router:Router,private formBuilder: FormBuilder,private user:UserService) { }

  merchantAddFormData:any;
  merchantAddForm:FormGroup;
    ngOnInit(): void
    {
        this.merchantAddForm = this.formBuilder.group({
          providerName:['',Validators.required]
      })  
    }

    onSubmit()
  {
    if(this.merchantAddForm.valid)
    {
      this.merchantAddFormData=this.merchantAddForm.value;
      console.log(this.merchantAddForm.value);
      this.user.merchantForm(this.merchantAddFormData).subscribe(data=>
      {
          let responseData=data
          alert('data is successfully saved');
      })
    }
  }

}
