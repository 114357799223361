import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {UserService} from '../user.service';

@Component({
  selector: 'app-user-transaction',
  templateUrl: './user-transaction.component.html',
  styleUrls: ['./user-transaction.component.css']
})
export class UserTransactionComponent implements OnInit {

  phoneNo:any;
  token:any;
  allData:any;

  constructor(public router: Router, private user: UserService) { }
  transList = [] as any;
  ngOnInit(): void 
  {
    // let data = localStorage.getItem('adminUserData');
    // if (data == undefined || data == null) 
    // {
    //   this.router.navigate(['/admin-login']);
    // }
    // else 
    // {
    //   const formData = new FormData();
    //   let userData = JSON.parse(data);
    //   formData.append('token', userData[0]['token']);
    //   this.user.getTransaction().subscribe(data => {
    //     let transData = data['transactionlist'];
    //     for (let i = 0; i < transData.length; i++) {
    //       let tempTransList ={} as  any;
    //       let currentData = JSON.parse(transData[i]['raw'])
    //       tempTransList['Date']=currentData['date'];
    //       tempTransList['OrderReference']=currentData['orderReference'];
    //       tempTransList['Amount']=currentData['amount'];
    //       tempTransList['status']=currentData['status'];
    //       tempTransList['channel']=currentData['channel'];
    //       this.transList.push(tempTransList)       
    //     }
    //   });
    // }
    let userData = localStorage.getItem('loggedInUserData');
    let data=JSON.parse(userData);
    let tempTransList ={} as  any;
    const transactionAPIData = new FormData();
    transactionAPIData.append('token',data[0]['token']);
    transactionAPIData.append('phoneNo',data[0]['phone']);
   //   this.transList.push(tempTransList);
      console.log(this.transList);
      this.user.transactionData(transactionAPIData).subscribe(data => {
          this.allData = data;
        console.log(this.allData);
      });
  }
}