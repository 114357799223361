import { Component, OnInit } from '@angular/core';
import {UserService} from '../user.service';

@Component({
  selector: 'app-merchant-list',
  templateUrl: './merchant-list.component.html',
  styleUrls: ['./merchant-list.component.css']
})
export class MerchantListComponent implements OnInit {

  fullData:any;  
  constructor(private user:UserService) { }

  ngOnInit()
  {
    this.user.getChannelList().subscribe(data=>
    {
      this.fullData=data
      console.log(this.fullData)
    })
  }

  onDelete(i)
  {
    console.log(i)
  }

}
