<ngx-spinner></ngx-spinner>
<section class="breadcrumb-area gradient-overlay" style="background: url('assets/images/banner/3.jpg');">
    <div class="container">
        <div class="row">
            <div class="col-xl-12">
                <div class="site-breadcrumb">
                    <h2>Admin Login</h2>
                </div>
            </div>
        </div>
    </div>
</section><!--/breadcrumb area-->

<!--Login Section -->
<section class="section-padding blue-bg shaded-bg">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-5 col-md-6 col-sm-12">
                <div class="site-form mb-30">
                    <form [formGroup]="adminLogInForm" novalidate (submit)="onSubmit()" #myform="ngForm">
                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-sm-12">
                                <input type="text" placeholder="Username" formControlName="username">
                                <div *ngIf= "adminLogInForm.get('username').hasError('required') && myform.submitted" style="color: red;">*Enter Your  Username</div>
                            </div>
                            <div class="col-xl-12 col-lg-12 col-sm-12">
                                <input type="password" placeholder="Password" formControlName="password">
                                <div *ngIf= "adminLogInForm.get('password').hasError('required') && myform.submitted" style="color: red;">*Enter Your  Password</div>
                            </div>
                            <div class="col-xl-12 col-lg-12 col-sm-12">
                                <button type="submit" class="bttn-mid btn-fill w-100">Login Account</button>
                            </div>
                            
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section><!--/Login Section-->