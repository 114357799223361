<app-header-component></app-header-component>
<section class="section-padding-sm-2 blue-bg">
    <div class="container">
        <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 mb-20">
                <div class="card">
                    <div class="card-header">
                        <img src="assets/images/new-user/1.jpg" alt=""> Profile <a href="profile.html"><i class="ti-arrow-top-right"></i></a>
                    </div>
                    <div class="card-body">
                        <p>Good Morning! <strong>Clark Mcbride</strong></p>
                        <p>Customer id: <strong>23658795</strong>; Last login: <strong>12-03-20</strong></p>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 mb-20">
                <div class="card">
                    <div class="card-header">
                        Balance
                    </div>
                    <div class="card-body">
                        <p>US Doller: <strong>$568</strong></p>
                        <p>GB Pound: <strong>£785</strong></p>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 mb-20">
                <div class="card">
                    <div class="card-header">
                        Bank account
                    </div>
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item"><i class="ti-check cl-primary"></i> Padlock Holdings Bank Corp.</li>
                        <li class="list-group-item"><a href=""><i class="ti-plus"></i> Add new Account</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section><!--Dashboard top-->  


<!--Dashboard Bottom-->  
<section class="section-padding-sm-2 blue-bg-2">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-9 col-lg-9 col-md-12 mb-20">
                <div class="card">
                    <div class="card-header">
                        Send money
                    </div>
                    <div class="card-body">
                        <div class="site-form mb-30">
                            <form action="#">
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-sm-12 mb-10">
                                        <label for="recipient-email">Recipient Email</label>
                                        <input type="email" id="recipient-email" placeholder="payment@example.com" required>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-sm-12 mb-10">
                                        <label for="recipient-country">Recipient Country</label>
                                        <select name="counrty" class="custom-select" id="recipient-country">
                                            <option value="">United States (US)</option>
                                            <option value="">Germany</option>
                                            <option value="">Italy</option>
                                            <option value="">United Kingdom (UK)</option>
                                            <option value="">France</option>
                                            <option value="">Poland</option>
                                            <option value="">Ireland</option>
                                        </select>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-sm-12 mb-10">
                                        <label for="recipient-currency">Recipient Currency</label>
                                        <select name="currency" class="custom-select" id="recipient-currency">
                                            <option value="">USD</option>
                                            <option value="">AUD</option>
                                            <option value="">GBP</option>
                                            <option value="">CAD</option>
                                        </select>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-sm-12 mb-10">
                                        <label for="amount">Your Amount</label>
                                        <input type="text" placeholder="Amount" id="amount" required>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-sm-12 mb-10">
                                        <label for="fee-generate">Transactions fees</label>
                                        <input type="text" readonly="readonly" value="0" id="fee-generate" required>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-sm-12 mb-10">
                                        <label for="payment-note">Payment Description (Optional)</label>
                                        <textarea name="msg" rows="4" placeholder="Payment note" id="payment-note"></textarea>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-sm-12">
                                        <button type="submit" class="bttn-mid btn-fill">Send money</button>
                                        <a href="" class="link ml-4">Preview it</a>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 mb-20">
                <div class="card mb-30">
                    <div class="card-header">
                        Someone to pay?
                    </div>
                    <div class="card-body">
                        <p>Boluptate quis optio, quibusdam quo unde cum dolores tempora veniam eius, voluptatum illum</p>
                        <a href="" class="link"><i class="ti-user"></i> Make a payment</a>
                    </div>
                </div>
                <div class="card mb-30">
                    <div class="card-header">
                        Earn $25 to invite
                    </div>
                    <div class="card-body">
                        <p>Boluptate quis optio, quibusdam quo unde cum dolores tempora veniam eius, voluptatum illum</p>
                        <a href="" class="link"><i class="ti-arrow-down"></i> refer a friend</a>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header">
                        Receive a payment?
                    </div>
                    <div class="card-body">
                        <p>Boluptate quis optio, quibusdam quo unde cum dolores tempora veniam eius, voluptatum illum</p>
                        <a href="" class="link"><i class="ti-direction"></i> Receive payment</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section><!--Dashboard Bottom-->

<!--newslatter-->
<section class="section-padding blue-bg">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="call-to-action centered">
                    <div class="section-title">
                        <h4><span>Top</span>Stay updated</h4>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-xl-6 col-lg-6 col-sm-12">
                            <div class="newslatter">
                                <form action="#">
                                    <input type="email" placeholder="Email Address" required>
                                    <button type="submit">Subscribe</button>
                                </form>
                                <div class="social">
                                    <a href=""><i class="fa fa-facebook"></i></a>
                                    <a href=""><i class="fa fa-instagram"></i></a>
                                    <a href=""><i class="fa fa-twitter"></i></a>
                                    <a href=""><i class="fa fa-pinterest"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section><!--/newslatter-->