import { Component, OnInit } from '@angular/core';
import {UserService} from '../user.service';

@Component({
  selector: 'app-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.css']
})
export class NewsListComponent implements OnInit {

  fullData:any;  
  constructor(private user:UserService) { }

  ngOnInit()
  {
    this.user.getnewsList().subscribe(data=>
    {
      this.fullData=data
      console.log(this.fullData)
    })
    error =>
    {
      this.fullData = error.message;
      console.error('There was an error!', error);
    }
  }
}
