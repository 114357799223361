<app-header-component></app-header-component>

<section class="breadcrumb-area gradient-overlay" style="background: url('assets/images/banner/3.jpg');">
  <div class="container">
    <div class="row">
      <div class="col-xl-12">
        <div class="site-breadcrumb">
          <h2>Transactions</h2>
        </div>
      </div>
    </div>
  </div>
</section>
<!--/breadcrumb area-->

<!--Privacy Section -->
<section class="section-padding-sm-2 blue-bg-2">
  <div class="container">
    <div class="row justify-content-center">

      <div class="col-xl-12 col-lg-12 col-md-12 mb-20">
        <div class="transaction-list table-responsive">
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">SR No</th>
                <th scope="col">Amount</th>
                <th scope="col">Channel</th>
                <th scope="col">Transaction Date</th>
                <th scope="col">Receiver No</th>
                <th scope="col">Transaction Id</th>
                <th scope="col">Status</th>
                <!-- <th scope="col">Bank or Card</th> -->
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of allData.userdetails" data-toggle="modal" data-target="#exampleModalCenter">
                <td><span>{{item.senderNo}}</span></td>
                <td><strong>{{item.amount}}</strong></td>
                <td><strong>{{item.channel}}</strong></td>
                <td><strong>{{item.transactionDate}}</strong></td>
                <td><strong>{{item.receiverNo}}</strong></td>
                <td><strong>{{item.transactionId}}</strong></td>
                <td><strong>{{item.status}}</strong></td>
                <!-- <td><strong class="cl-primary">USD XXXX-2359</strong></td> -->
              </tr>
              <!-- <tr data-toggle="modal" data-target="#exampleModalCenter">
                            <td><span>13 March, 2020</span></td>
                            <td><strong>Rahul payment</strong> <span>- Refund</span></td>
                            <td><strong>- $589.9</strong></td>
                            <td><i class="ti-close cl-red" data-toggle="tooltip" data-placement="top" title="" data-original-title="Cancelled"></i></td>
                            <td><strong class="cl-primary">USD Bank of Rob</strong></td>
                          </tr>
                          <tr data-toggle="modal" data-target="#exampleModalCenter">
                            <td><span>12 March, 2020</span></td>
                            <td><strong>Amazon Payment service</strong> <span>- Send</span></td>
                            <td><strong>- £239.85</strong></td>
                            <td><i class="ti-check cl-primary" data-toggle="tooltip" data-placement="top" title="" data-original-title="Finished"></i></td>
                            <td><strong class="cl-primary">GBP XXXX-5879</strong></td>
                          </tr>
                          <tr data-toggle="modal" data-target="#exampleModalCenter">
                            <td><span>10 March, 2020</span></td>
                            <td><strong>Namecheap Domain Buy</strong><span> - Cost</span></td>
                            <td><strong>- $13.25</strong></td>
                            <td><i class="ti-flag-alt cl-yellow" data-toggle="tooltip" data-placement="top" title="" data-original-title="Processing"></i></td>
                            <td><strong class="cl-primary">USD XXXX-2359</strong></td>
                          </tr> -->
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</section>