<app-header-component></app-header-component>
<ngx-spinner></ngx-spinner>

<section class="section-padding-sm-2 blue-bg">
    <div class="container">
        <div class="row">
            
        </div>
    </div>
</section><!--Dashboard top-->  


<!--Dashboard Bottom-->  
<section class="section-padding-sm-2 blue-bg-2">
    <div class="container">
        <div class="row justify-content-center">
            
            <div class="col-xl-12 col-lg-12 col-md-12 mb-20">
                <div class="card mb-30">
                    <div class="card-header">
                        Profile Info <a href="" data-toggle="tooltip" data-placement="top" title="" data-original-title="Edit Profile info"><i class="ti-pencil"></i></a>
                    </div>
                    <div class="card-body">
                        <div class="profile-card-body">
                            <div class="row">
                                <div class="col-sm-3">
                                    <div class="thumb text-right">
                                        <img src="assets/images/new-user/1.jpg" alt="">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-3 col-3">
                                    <div class="left-info text-right">
                                        <p>First Name</p>
                                        <p>Last Name</p>
                                        <p>Email</p>
                                        <p>Phone No</p>
                                        <p>Reward Pt</p>
                                    </div>
                                </div>
                                <div class="col-sm-9 col-9">
                                    <div class="right-info">
                                        <p>{{userData.firstname}}</p>
                                        <p>{{userData.lastname}}</p>
                                        <p>{{userData.email}}</p>
                                        <p>{{userData.phone}}</p>
                                        <p>{{userData.rewardPt}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</section><!--Dashboard Bottom-->
