import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../user.service';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.css']
})
export class TransactionsComponent implements OnInit {

  constructor(public router: Router, private user: UserService) { }
  transList = [] as any;
  ngOnInit(): void {
    let data = localStorage.getItem('adminUserData');
    if (data == undefined || data == null) {
      this.router.navigate(['/admin-login']);
    }
    else {
      const formData = new FormData();
      let userData = JSON.parse(data);
      formData.append('token', userData[0]['token']);
      this.user.getTransaction(formData).subscribe(data => {
        this.transList = data['transactionlist'];
        // for (let i = 0; i < transData.length; i++) {
        //   let tempTransList ={} as  any;
        //   let currentData = JSON.parse(transData[i]['raw'])
        //   tempTransList['Date']=currentData['date'];
        //   tempTransList['OrderReference']=currentData['orderReference'];
        //   tempTransList['Amount']=currentData['amount'];
        //   tempTransList['status']=currentData['status'];
        //   tempTransList['channel']=currentData['channel'];
        //   this.transList.push(tempTransList);
       
       
        // }
      });
    }
  }
}


