import { BrowserModule } from '@angular/platform-browser';
import { NgModule,CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { CommonModule } from "@angular/common";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LogInComponent } from './log-in/log-in.component';
import { PayNowComponent } from './pay-now/pay-now.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { HowItWorksComponent } from './how-it-works/how-it-works.component';
import { RegistrationComponent } from './registration/registration.component';
import { HeaderComponent } from './header-component/header-component.component';
import { PayBillsComponent } from './pay-bills/pay-bills.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ContactusComponent } from './contactus/contactus.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { AdminLoginComponent } from './admin-login/admin-login.component';
import { FormsModule,ReactiveFormsModule} from '@angular/forms';
import{HttpClientModule} from '@angular/common/http';
import { UserListComponent } from './user-list/user-list.component'
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ToastrModule } from 'ngx-toastr';
import { NewsListComponent } from './news-list/news-list.component';
import { MerchantListComponent } from './merchant-list/merchant-list.component';
import { MerchantAddComponent } from './merchant-add/merchant-add.component';
import { NewsAddComponent } from './news-add/news-add.component';
import { UserTransactionComponent } from './user-transaction/user-transaction.component';
import { AdminProfileComponent } from './admin-profile/admin-profile.component';


@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LogInComponent,
    PayNowComponent,
    AboutUsComponent,
    HowItWorksComponent,
    RegistrationComponent,
    HeaderComponent,
    PayBillsComponent,
    PrivacyPolicyComponent,
    ContactusComponent,
    UserProfileComponent,
    TransactionsComponent,
    AdminLoginComponent,
    UserListComponent,
    NewsListComponent,
    MerchantListComponent,
    MerchantAddComponent,
    NewsAddComponent,
    UserTransactionComponent,
    AdminProfileComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxSpinnerModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
