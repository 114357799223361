<ngx-spinner></ngx-spinner>

<section class="breadcrumb-area gradient-overlay" style="background: url('assets/images/banner/3.jpg');">
    <div class="container">
        <div class="row">
            <div class="col-xl-12">
                <div class="site-breadcrumb">
                    <h2>Login Account</h2>
                </div>
            </div>
        </div>
    </div>
</section><!--/breadcrumb area-->

<!--Login Section -->
<section class="section-padding blue-bg shaded-bg">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 centered">
                <div class="section-title cl-white">
                    <h4><span>New</span>Login account</h4>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-5 col-md-6 col-sm-12">
                <div class="site-form mb-30">
                    <form [formGroup]="LogInForm" novalidate (submit)="onSubmit()" #myform="ngForm">
                        <div class="row">
                            <div class="col-md-3" style="padding-right:0px">
                                <span style="height: 55px;padding-left: 25px;" class="input-group-text" id="inputGroupPrepend">242</span>    
                            </div>
                            <div class="col-xl-9 col-lg-9 col-sm-9">
                                <input type="text" placeholder="Mobile Number" formControlName="phone">
                                <div *ngIf= "LogInForm.get('phone').hasError('required') && myform.submitted" style="color: red;">*Enter Your  Phone</div>
                            </div>
                            <div class="col-xl-12 col-lg-12 col-sm-12">
                                <input type="password" placeholder="Password" formControlName="password">
                                <div *ngIf= "LogInForm.get('password').hasError('required') && myform.submitted" style="color: red;">*Enter Your  Username</div>
                            </div>
                            <div class="col-xl-12 col-lg-12 col-sm-12">
                                <button type="submit" class="bttn-mid btn-fill w-100">Login Account</button>
                            </div>
                            <div class="col-xl-12 col-lg-12 col-sm-12">
                                <div class="extra-links">
                                    <a href="javascript:void(0);" (click)="redirectRegister()" class="cl-white">Create new account</a>
                                    <a href="#" class="cl-white">Forget Password?</a>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section><!--/Login Section-->