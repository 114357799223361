import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {

  constructor(private user: UserService, private spinner: NgxSpinnerService,private router:Router) { }

  //userId = 5;
  userData: any;
  ngOnInit(): void {
    let data = localStorage.getItem('loggedInUserData');
    if (data == undefined || data == null) {
      this.router.navigate(['/login'])
    }
    else {
      this.spinner.show();
      let userJsonData =JSON.parse(data);
      this.user.UserProfileData(userJsonData[0]['userId']).subscribe(data => {
        this.spinner.hide();
        this.userData = data['userdetails'][0];
        // alert(responseData);
        console.log(data);
      })
    }
  }
}
