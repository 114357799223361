<app-header-component></app-header-component>
<section class="breadcrumb-area gradient-overlay" style="background: url('assets/images/banner/3.jpg');">
    <div class="container">
        <div class="row">
            <div class="col-xl-12">
                <div class="site-breadcrumb">
                    <h2>User List</h2>
                </div>
            </div>
        </div>
    </div>
</section><!--/breadcrumb area-->
<!--Privacy Section -->
<section class="section-padding-sm-2 blue-bg-2">
    <div class="container">
        <div class="row justify-content-center">
            
            <div class="col-xl-12 col-lg-12 col-md-12 mb-20">
                <div class="transaction-list table-responsive">
                    <table class="table table-hover">
                        <thead>
                          <tr>
                            <th scope="col">Name</th>
                            <!-- <th scope="col"></th> -->
                            
                            <th scope="col">Phone</th>
                            <th scope="col">Email</th>
                            <th scope="col">User Channel</th>
                            <th scope="col">Total Amount</th>
                            <th scope="col">Reward Point</th>
                          </tr>
                        </thead>
                        <tbody >
                          <tr *ngFor="let item of userList"   data-toggle="modal" data-target="#exampleModalCenter">
                            <td><span>{{item.firstname}} {{item.lastname}}</span></td>
                            <td><span>{{item.phone}}</span></td>
                            <td><strong>{{item.email}}</strong></td>
                            <td><strong>{{item.channelName}}</strong></td>
                            <td><strong>{{item.total}}</strong></td>
                            <!-- <td><i class="ti-check cl-primary" data-toggle="tooltip" data-placement="top" title="" data-original-title="Finished"></i></td> -->
                            <td><strong class="cl-primary">{{item.rewardPt}}</strong></td>
                          </tr>
                         
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>
