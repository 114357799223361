<app-header-component></app-header-component>
<section class="breadcrumb-area gradient-overlay" style="background: url('assets/images/banner/3.jpg');">
    <div class="container">
        <div class="row">
            <div class="col-xl-12">
                <div class="site-breadcrumb">
                    <h2>Provider List</h2>
                </div>
            </div>
        </div>
    </div>
</section><!--/breadcrumb area-->

<!--Privacy Section -->
<section class="section-padding-sm-2 blue-bg-2">
    <div class="container">
        <div class="row justify-content-center">
            
            <div class="col-xl-12 col-lg-12 col-md-12 mb-20">
                <div class="transaction-list table-responsive">
                    <table class="table table-hover">
                        <thead>
                          <tr>
                            <th scope="col">SR No.</th>
                            <th scope="col">Provider Name</th>
                            <th scope="col">Delete</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of fullData.channellist,index as i">
                            <td>{{item.channelId}}</td>
                            <td>{{item.channelName}}</td>
                            <td><button (click)="onDelete(i+1)">Delete</button></td>
                          </tr>                          
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>
