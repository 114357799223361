<header class="header-area blue-bg-2">
    <nav class="navbar navbar-expand-lg main-menu">
        <div class="container-fluid">

            <p style="font-size: 38px;padding-top:10px;color: white">Cashless</p>

            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="menu-toggle"></span>
            </button>

            <div *ngIf="!isAdmin" class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav m-auto">
                    
                    <li class="nav-item"><a class="nav-link" (click)="redirectDashboard()" href="javascript:void(0);">Home</a></li>
                    <li class="nav-item"><a class="nav-link" href="javascript:void(0);" (click)="redirectHowItWorks()" >How it works</a></li>
                    <li class="nav-item"><a class="nav-link" href="javascript:void(0);" (click)="redirectPrivacy()">Privacy Policy</a></li>
                    <li class="nav-item dropdown">
                      <a class="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Pay Now</a>
                      <ul class="dropdown-menu">
                        <li><a class="dropdown-item" href="javascript:void(0);" (click)="redirectPaynow()">Pay Money</a></li>
                        <li><a class="dropdown-item" href="javascript:void(0);" (click)="redirectPaybills()">Pay Bills</a></li>
                      </ul>
                    </li>
                    <li class="nav-item"><a class="nav-link" href="javascript:void(0);" (click)="redirectContactus()">Contact</a></li>
                    <!-- <li class="nav-item"><a class="nav-link" (click)="redirectProfile()" href="javascript:void(0);">Profile</a></li> -->
                    <!-- <li class="nav-item"><a class="nav-link" (click)="redirectUserTransaction()" href="javascript:void(0);">Transaction</a></li> -->
                </ul>
                <div  *ngIf="isLoggedIn" class="header-btn justify-content-end">
                    <!-- <a href="javascript:void(0);" class="bttn-small btn-fill" (click)="logOut()">Logout</a> -->

                    <div class="header-btn justify-content-end">
                        <li class="nav-item dropdown"  style="right: 133px;">
                            <a class="nav-link dropdown-toggle" style="color: whitesmoke;" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{phoneNo}}</a>
                            <ul class="dropdown-menu">
                              <li><a class="dropdown-item" href="javascript:void(0);" (click)="redirectUserTransaction()">Transaction</a></li>
                              <li><a class="dropdown-item" href="javascript:void(0);" (click)="redirectProfile()">Profile</a></li>
                              <li><a class="dropdown-item" href="javascript:void(0);" (click)="logOut()">Log Out</a></li>
                            </ul>
                          </li>
                        <!-- <a href="javascript:void(0);" class="bttn-small btn-fill" (click)="logOut()">Logout</a> -->
                    </div>

                    
                </div>
                <div  *ngIf="isAdmin" class="header-btn justify-content-end">
                  <!-- <a href="javascript:void(0);" class="bttn-small btn-fill" (click)="logOut()">Logout</a> -->

                  <!-- <div class="header-btn justify-content-end">
                      <li class="nav-item dropdown">
                          <a class="nav-link dropdown-toggle" style="color: whitesmoke;" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{phoneNo}}</a>
                          <ul class="dropdown-menu">
                            <li><a class="dropdown-item" href="javascript:void(0);" (click)="redirectUserTransaction()">Transaction</a></li>
                            <li><a class="dropdown-item" href="javascript:void(0);" (click)="redirectProfile()">Profile</a></li>
                            <li><a class="dropdown-item" href="javascript:void(0);" (click)="logOut()">Log Out</a></li>
                          </ul>
                        </li> -->
                      <a href="javascript:void(0);" class="bttn-small btn-fill" (click)="logOut()">Logout</a>
                  <!-- </div> -->

                  
              </div>
                <div *ngIf="!isLoggedIn" class="header-btn justify-content-end">
                    <!-- <a href="javascript:void(0);" class="bttn-small btn-fill" (click)="logOut()">Login</a> -->

                    <div class="header-btn justify-content-end">
                        <!-- <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" style="color: whitesmoke;" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{phoneNo}}</a>
                            <ul class="dropdown-menu">
                              <li><a class="dropdown-item" href="javascript:void(0);" (click)="redirectUserTransaction()">Transaction</a></li>
                              <li><a class="dropdown-item" href="javascript:void(0);" (click)="redirectProfile()">Profile</a></li>
                              <li><a class="dropdown-item" href="javascript:void(0);" (click)="logOut()">Log Out</a></li>
                            </ul>
                          </li> -->
                        <a href="javascript:void(0);" class="bttn-small btn-fill" (click)="logOut()">Login</a>
                    </div>

                </div>
                
            </div>
            <div *ngIf="isAdmin" class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav m-auto">
                    
                    <li class="nav-item"><a class="nav-link" (click)="redirectuserList()" href="javascript:void(0);">User List</a></li>
                    <li class="nav-item"><a class="nav-link" href="javascript:void(0);" (click)="redirectTransactions()" >Transactions</a></li>
                    <li class="nav-item"><a class="nav-link" href="javascript:void(0);" (click)="redirectNews()">News List</a></li>
                    <li class="nav-item"><a class="nav-link" href="javascript:void(0);" (click)="redirectProvider()">Provider List</a></li>
                    <!-- <li class="nav-item"><a class="nav-link" href="javascript:void(0);" (click)="redirectNews()">Pay Bills</a></li> -->

                  
                    <!-- <li class="nav-item"><a class="nav-link" href="javascript:void(0);" (click)="redirectContactus()">Contact</a></li> -->
                </ul>
                <div class="header-btn justify-content-end">
                  <div class="header-btn justify-content-end">
                    <li class="nav-item dropdown" style="right: 133px;">
                        <a class="nav-link dropdown-toggle" style="color: whitesmoke;" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Admin</a>
                        <ul class="dropdown-menu">
                          <!-- <li><a class="dropdown-item" href="javascript:void(0);" (click)="redirectUserTransaction()">Change</a></li> -->
                          <li><a class="dropdown-item" href="javascript:void(0);" (click)="redirectAdminProfile()">Profile</a></li>
                          <li><a class="dropdown-item" href="javascript:void(0);" (click)="logOut()">Log Out</a></li>
                        </ul>
                      </li> 
                    <!-- <a href="javascript:void(0);" class="bttn-small btn-fill" (click)="logOut()">Logout</a> -->
                </div>
                    <!-- <a href="javascript:void(0);" class="bttn-small btn-fill" (click)="logOut()">Logout</a> -->
                </div>
            </div>
        </div>
    </nav>
</header>