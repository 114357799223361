import { Component, OnInit } from '@angular/core';
import { FormGroup , FormControl , Validators,NgForm, FormGroupDirective,FormBuilder } from '@angular/forms';
import {Router} from '@angular/router';
import  {UserService} from '../user.service'

@Component({
  selector: 'app-news-add',
  templateUrl: './news-add.component.html',
  styleUrls: ['./news-add.component.css']
})
export class NewsAddComponent implements OnInit {

  constructor(public router:Router,private formBuilder: FormBuilder,private user:UserService) { }

  newsFormData:any;
  newsForm:FormGroup;
    ngOnInit(): void
    {
        this.newsForm = this.formBuilder.group({
          date:['',Validators.required],
          description:['',Validators.required],
          heading:['',Validators.required]
      })  
    }

  onSubmit()
  {
    if(this.newsForm.valid)
    {
      this.newsFormData=this.newsForm.value;
      console.log(this.newsForm.value);
      this.user.newsForm(this.newsFormData).subscribe(data=>
      {
          let responseData=data
          alert('data is successfully saved');
      })
    }
  }
}
